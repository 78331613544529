import React from 'react';
import './intro.css';

const Intro = () => (
    <section className="intro">
        <h1 className="intro__heading">Hello, I'm <strong>Steven Wilshaw</strong>.</h1>
        <p className="intro__description">I'm a contract software developer<br />based in South Manchester.</p>
        <ul className="intro__links">
            <li>
                <a href="https://www.linkedin.com/in/stevenwilshaw/" target="_blank" rel="noopener noreferrer" className="intro__button">View my LinkedIn profile</a>
            </li>
        </ul>
    </section>
);

export default Intro;
