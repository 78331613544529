import React from 'react';
import './app.css';
import Intro from "./Intro";
import logo from "./logo.svg";
import Div100vh from 'react-div-100vh'

const App = () => (
    <Div100vh style={{height: '100rvh'}}>
        <div className="app">
            <header className="app__header">
                <a href="/">
                    <img src={logo} alt="{SW}" height="30"/>
                </a>
            </header>

            <div className="app__content">
                <Intro />
            </div>

            <footer className="app__footer">
                &copy; 2019 Steven Wilshaw
            </footer>
        </div>
    </Div100vh>
);

export default App;
